import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CART_ADD_TEALIUM } from '../../../../constants/Tealium';
import {
  GUEST_AND_CART_DETAILS_ACTION,
  SET_ADD_TO_CART_LOADING_ACTION,
} from '../../../../redux/actions/order.actions';
import { DELETE_REMOVE_WISH_LIST_ACTION } from '../../../../redux/actions/wish-list.actions';
import { orderSelector } from '../../../../redux/selectors/order.selector';
import { wishListSelector } from '../../../../redux/selectors/wish-list.selector';
import { useGetPageType } from '../../../../utils/hooks/get-page-type/GetPageType';
import { ProductUtility } from '../../../../utils/product-utility';
import {
  checkTruthy,
  sendTealiumData,
  checkMembership,
} from '../../../../utils/utils';
import { CartService } from '../../../../_foundation/apis/cart/cart.service';
import { useSite } from '../../../../_foundation/hooks/usesite/useSite';
import { NteButton } from '../../../Forms/NteButton/NteButton';
import { ProductPricing } from '../../../ProductPricing/ProductPricing';
import { useCalculateStockStatus } from '../ProductCard/hooks/CalculateStockStatusHooks';
import { useStorePickupMessageHook } from '../ProductCard/hooks/StorePickupMessageHook';
import { ProductCardProps } from '../ProductCard/ProductCard';
import { ProductCardConstants } from '../ProductCard/ProductCardConstants';
import { ProductCardSaveList } from '../ProductCard/ProductCardSaveList/ProductCardSaveList';
import { EntryTypeCode } from '../ProductCard/ProductCardSection';
import { ProductCardSectionImage } from '../ProductCard/ProductCardSectionImage/ProductCardSectionImage';
import { ProductShipping } from '../ProductCard/ProductCardSectionMessages/ProductShipping/ProductShipping';
import { ProductCompare } from '../ProductCard/ProductCompare/ProductCompare';
import { ProductTitle } from '../ProductTitle/ProductTitle';
import { Rating } from '../Rating/Rating';
import { authenticationSelector } from '../../../../redux/selectors/auth.selector';

const GridProductCard = ({
  productCard,
  removeFromList,
  isCompareProduct,
  index,
  sharedList,
  showRemove = true,
  className = '',
  isProductCompare,
  sortIndex,
  isGroupedInventory,
  displayCompareCheckbox = true,
}: ProductCardProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();

  const { mySite } = useSite();

  const {
    PRODUCT_CARD_SECTION2: { ITEM_PARTNUMBER },
    ITEM,
    PRODUCT,
    GIFTCARD_PAY_METHOD,
    PRODUCT_URL,
  } = ProductCardConstants;

  const [buyableWithNoPrice, setBuyableWithNoPrice] = useState(false);

  const { giftItemIds, giftListIds, giftList } = useSelector(wishListSelector);

  const { addToCartLoading, paymentInstruction } = useSelector(orderSelector);

  const { stockStatus } = useCalculateStockStatus(productCard);

  const { storePickUpLabel, inStockMessage } = useStorePickupMessageHook(
    productCard,
    isProductCompare
  );

  const { pageType } = useGetPageType();

  const { isUserAuthenticated, userType } = useSelector(authenticationSelector);

  const partNumber = productCard.partNumber;

  const name = productCard.name;

  const productId = productCard.uniqueID
    ? productCard.uniqueID
    : productCard.id;

  const descName: string = giftList.find(
    (wishList) => wishList?.uniqueID === giftListIds[productId]
  )?.descriptionName!;

  const showStoreOnly = ProductUtility.isStoreOnly(productCard);

  const catalogEntryTypeCode = productCard?.catalogEntryTypeCode;

  const productCardType = productCard?.type;

  const cartButtonLoading = addToCartLoading[partNumber];

  const isAdvantageExclusives =
    ProductUtility.processAdvantageExclusives(productCard);

  const { isAdvantage } = checkMembership(userType);

  /**
   * Added || condition -- we are using v1 and v2 API
   */
  const hideProductShippingMessages =
    (catalogEntryTypeCode === EntryTypeCode.ITEMBEAN ||
      showStoreOnly ||
      productCardType?.toLowerCase() === ITEM) &&
    (catalogEntryTypeCode !== EntryTypeCode.PRODUCTBEAN ||
      productCardType?.toLowerCase() !== PRODUCT);

  const [buttonProps, setButtonProps] = useState<any>({
    label: t(ProductCardConstants.PRODUCT_CARD_SECTION3.BUTTON.ADD_TO_CART),
    variant: 'contained',
  });

  const [showStoreStockDrawer, setShowStoreStockDrawer] =
    useState<boolean>(false);

  const storeStockCloseHandler = (): void => {
    setShowStoreStockDrawer(
      (showStoreStockDrawer: boolean) => !showStoreStockDrawer
    );
  };

  const { shippingMessage, sameDayShippingMessage } =
    ProductUtility.processShippingMessage(productCard, stockStatus, pageType);

  const showAdvantageExclusivesBadge =
    isUserAuthenticated && isAdvantage && isAdvantageExclusives;

  const addToCartDetails = () => {
    /**
     * Executes only if Add to Cart button is clicked.
     * Item will be added to Cart.
     */
    if (
      catalogEntryTypeCode !== EntryTypeCode.PRODUCTBEAN &&
      !(
        buttonProps.label ===
        t(ProductCardConstants.PRODUCT_CARD_SECTION3.BUTTON.SEE_DETAILS)
      )
    ) {
      dispatch(
        SET_ADD_TO_CART_LOADING_ACTION({
          loading: true,
          partNumber: productCard?.partNumber,
        })
      );

      /**
       * responsible to delete the Gift card Payment instruction
       * when user coming back from checkout flow and doing additional changes to cart
       */
      if (paymentInstruction) {
        const giftCardPaymentInstruction = paymentInstruction?.filter(
          ({ payMethodId }) =>
            payMethodId.toLowerCase() === GIFTCARD_PAY_METHOD.toLowerCase()
        );

        if (giftCardPaymentInstruction?.length > 0) {
          giftCardPaymentInstruction.forEach(({ piId }) =>
            CartService.removeGiftCard({
              storeId: mySite.storeID,
              paymentInstructionId: piId,
            })
          );
        }
      }

      dispatch(
        GUEST_AND_CART_DETAILS_ACTION({
          storeID: mySite.storeID,
          partNumber: partNumber,
        })
      );

      sendTealiumData({
        tealium_event: CART_ADD_TEALIUM,
        product_id: partNumber,
        product_name: productCard.name,
      });
    }

    /**
     * Executes only if See Options or See Details button is clicked.
     * Navigates to PDP page.
     */
    if (
      catalogEntryTypeCode === EntryTypeCode.PRODUCTBEAN ||
      buttonProps.label ===
        t(ProductCardConstants.PRODUCT_CARD_SECTION3.BUTTON.SEE_DETAILS)
    ) {
      history.push(productCard?.seo?.href);
    }

    if (removeFromList && giftItemIds[productId] && giftListIds[productId]) {
      dispatch(
        DELETE_REMOVE_WISH_LIST_ACTION({
          storeID: mySite.storeID,
          wishListId: giftListIds[productId],
          itemId: giftItemIds[productId],
          productId,
          wishListPlp: true,
          descriptionName: `${t(descName)}`,
          productPartNumber: partNumber,
        })
      );
    }
  };

  /**
   * @method
   * Responsible to set the ButtonProps based on requirements
   */
  const setCtaButtonProps = useCallback((): void => {
    if (
      showStoreOnly ||
      !checkTruthy(productCard?.buyable) ||
      buyableWithNoPrice
    ) {
      setButtonProps({
        label: t(ProductCardConstants.PRODUCT_CARD_SECTION3.BUTTON.SEE_DETAILS),
        variant: 'outlined',
      });
      return;
    }
    switch (catalogEntryTypeCode) {
      case EntryTypeCode.ITEMBEAN:
        if (!showStoreOnly)
          setButtonProps({
            label: t(
              ProductCardConstants.PRODUCT_CARD_SECTION3.BUTTON.ADD_TO_CART
            ),
            variant: 'contained',
          });
        break;
      case EntryTypeCode.PRODUCTBEAN:
        if (!showStoreOnly)
          setButtonProps({
            label: t(
              ProductCardConstants.PRODUCT_CARD_SECTION3.BUTTON.SEE_OPTIONS
            ),
            variant: 'outlined',
          });
        break;
    }
  }, [
    buyableWithNoPrice,
    catalogEntryTypeCode,
    productCard?.buyable,
    showStoreOnly,
    t,
  ]);

  useEffect(() => {
    setCtaButtonProps();
  }, [catalogEntryTypeCode, setCtaButtonProps, showStoreOnly]);

  const productNoImage = process.env.REACT_APP_PRODUCT_NO_IMAGE?.replace(
    /^\/+/,
    ''
  );

  return (
    <div
      className={`nte-card grid-product-card`}
      role='group'
      aria-label='product'>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ProductCardSectionImage
            productName={name}
            productImage={
              productCard.fullImageRaw
                ? productCard.fullImageRaw
                : productCard.thumbnailRaw
                ? productCard.thumbnailRaw
                : `${process.env.REACT_APP_ASSET_HOST}${productNoImage}`
            }
            partNumber={partNumber}
            isCompareProduct={isCompareProduct}
            showRemove={showRemove}
            productUrl={
              productCard?.seo?.href.includes(PRODUCT_URL)
                ? productCard?.seo?.href
                : PRODUCT_URL + productCard?.seo?.href
            }
            showRating={false}
            showAdvantageExclusivesBadge={showAdvantageExclusivesBadge}
          />
        </Grid>
        <Grid item xs={12}>
          <ProductTitle
            href={
              productCard?.seo?.href.includes(PRODUCT_URL)
                ? productCard?.seo?.href
                : PRODUCT_URL + productCard?.seo?.href
            }
            name={productCard?.name}
          />
        </Grid>
        <Grid item xs={12}>
          <div className='left-padding'>
            <Typography variant='body2' className='part-number'>
              {ITEM_PARTNUMBER}
              {partNumber}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Rating
            partNumber={partNumber}
            isSimpleProductCard={false}
            isCompareProduct={isCompareProduct}
          />
        </Grid>
        <Grid item xs={12}>
          <ProductPricing
            productCard={productCard}
            setBuyableWithNoPrice={setBuyableWithNoPrice}
          />
        </Grid>
        {catalogEntryTypeCode !== EntryTypeCode.PRODUCTBEAN ? (
          <Grid item xs={12}>
            <ProductShipping
              shippingMessage={shippingMessage}
              sameDayDeliveryMessage={sameDayShippingMessage}
              storePickupMessage={storePickUpLabel?.label}
              partNumber={partNumber}
              productName={name}
              showStoreStockDrawer={showStoreStockDrawer}
              storeStockCloseHandler={storeStockCloseHandler}
              productId={productId}
              productCard={productCard}
              isProductCompare={isProductCompare}
              isStockMessage={inStockMessage}
              lineType={ProductUtility.getLineType(productCard)}
            />
          </Grid>
        ) : (
          <div className='empty_space_large'></div>
        )}
        <Grid item xs={12} className='aligned-bottom-cart-btn'>
          <div className='cta-cell '>
            <NteButton
              classes={{ root: 'Add-to-cart-button' }}
              variant={buttonProps.variant}
              translationKey={buttonProps.label}
              onClick={addToCartDetails}
              fullWidth={true}
              size='small'
              loading={cartButtonLoading}
              type='button'
            />

            <div
              className={`field-labels grid-field-labels ${
                !hideProductShippingMessages ? 'section-visibility' : ''
              }`}>
              {!sharedList && displayCompareCheckbox && (
                <ProductCompare id={partNumber} name={name} />
              )}

              {!sharedList && (
                <ProductCardSaveList
                  id={productId}
                  productData={productCard}
                  name={name}
                  partNumber={partNumber}
                  descriptionName={`${t(descName)}`}
                />
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export { GridProductCard };
